import { render, staticRenderFns } from "./tai-bu-dai.vue?vue&type=template&id=43555ec3&scoped=true&"
import script from "./tai-bu-dai.vue?vue&type=script&lang=js&"
export * from "./tai-bu-dai.vue?vue&type=script&lang=js&"
import style0 from "./tai-bu-dai.vue?vue&type=style&index=0&id=43555ec3&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43555ec3",
  null
  
)

export default component.exports